<template>
  <div class="h-screen font-sans login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="relative w-full  sm:max-w-lg">
        <div
          class="card bg-blue-400 shadow-lg h-full rounded-3xl absolute transform -rotate-6"
        ></div>
        <div
          class="card bg-red-400 shadow-lg h-full rounded-3xl absolute transform rotate-6"
        ></div>

        <div
          class="relative w-full pb-10 rounded-3xl px-6 py-4 bg-gray-100 shadow-md"
        >
          <label
            for=""
            class="block mt-10 text-2xl text-gray-700 text-center font-semibold"
          >
            {{userInfo.storeName}}
          </label>
          <div class="mt-10" v-for="item in userInfo.tableData" :key="item.id">
            <div class="box">
              <div class="box-room-name" style="font-size:20px;">{{item.name}}</div>
              <div class="box-left text-green-600 font-semibold" v-if="item.on_state == 1" >空闲</div>
              <div class="box-left text-yellow-600 font-semibold" v-else-if ="item.on_state == 3">待保洁</div>
              <div class="box-left text-red-700 font-semibold" v-else>使用中</div>
              <div class="box-center">
                  <button
                  @click="ChangeRoomStateOnState(item.id)"
                    class="flex bg-blue-500 justify-center font-normal w-full p-1 pl-3 pr-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                  >
                    设空闲
                  </button>
              </div>
              <div class="box-right">
                <div class="box-right-on">
                                    <button
                                    @click="changeStateRoomSwitchStateOn(item.id)"
                    class="flex bg-green-500 justify-center font-normal w-full p-2 pl-4 pr-4 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                  >
                    开灯
                  </button>
                </div>
                <div class="box-right-off">
                  <button
                  @click="roomOff(item.id)"
                    class="flex bg-red-500 justify-center font-normal w-full p-2 pl-4 pr-4 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                  >
                    关灯
                  </button>
                </div>
              </div>
            </div>

            

 
        
          </div>
          <!-- <form class="mt-10" @click="onSubmit"></form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { useMessage } from "@/src/utils/useMessage.js";
import { useRouter, useRoute } from "vue-router";
import { http, http2 } from "@/utils/http";
import { onMounted, reactive, ref, onUnmounted, inject } from "vue";
import { useMessage } from "@/utils/useMessage.js";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userInfo = reactive({
      storeName: "",
      tableData: [],
    });

    // 获取 URL 参数
    const authParam = route.query.token;

    // 获取用户信息
    const getAllRoom = () => {
      http
        .get("/v1/cleaner/getRoomStateAllRoom?token=" + authParam, {})
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          userInfo.storeName = res.data.data.storeName;
          userInfo.tableData = res.data.data.room;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    };
    // 页面加载
    onMounted(() => {
      getAllRoom();
    });
    // 修改包间状态
    const ChangeRoomStateOnState = (roomid) => {
      try {
         http
        .get("/v1/cleaner/changeRoomStateOnState?token=" + authParam+"&roomId="+roomid, {})
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          getAllRoom();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        }).catch((error) => {
            console.error("发生错误:", error);
            // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
          });
      } catch (error) {
        console.error("请求失败", error);
      }
    };

    // 包间开门通电
    const changeStateRoomSwitchStateOn = (roomid) => {
      try {
         http
        .get("/v1/cleaner/changeStateRoomSwitchStateOn?token=" + authParam+"&roomId="+roomid, {})
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          getAllRoom();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        }).catch((error) => {
            console.error("发生错误:", error);
            // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
          });
      } catch (error) {
        console.error("请求失败", error);
      }
    };

    // 包间断电
    const roomOff = (roomid) => {
      try {
         http
        .get("/v1/cleaner/roomOff?token=" + authParam+"&roomId="+roomid, {})
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          getAllRoom();
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        }).catch((error) => {
            console.error("发生错误:", error);
            // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
          });
      } catch (error) {
        console.error("请求失败", error);
      }
    };

    return {
      userInfo,
      ChangeRoomStateOnState,
      changeStateRoomSwitchStateOn,
      roomOff,
    };
  },
};
</script>

<style lang="scss" scoped>
.toUp {
  margin-top: -40px;
  transition: all 0.5s;
}

.list {
  list-style: none;
  text-align: center;
  overflow: hidden;
  height: 40px;
  padding: 0;
  margin: 0;
}
li {
  text-align: left;
  height: 40px;
  line-height: 40px;
}
.box {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .box-left {
  }
  .box-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .box-right-on {
      margin-right: 10px;
    }
  }
}
</style>

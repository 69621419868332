<script  >
import { useRouter, useRoute } from "vue-router";
import { reactive, ref, onMounted } from "vue";
import { http } from "@/utils/http";
import { useMessage } from "@/utils/useMessage.js";
import swal from "sweetalert";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    // 获取 URL 参数
    const authParam = route.query.auth;

    const textCopy = ref();
    const userInfo = reactive({
      cdk: "",
      tableData: [],
    });
    // 跳转登录
    const toLogin = () => {
      router.push("/home?auth=" + authParam);
    };

    const handleEdit = (index, row) => {
      const text = row.url; // 复制文本内容
      // const input = textCopy.value;
      const input = textCopy.value;
      input.value = text; // 修改input的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 浏览器复制
      useMessage("success", "链接复制成功");
    };
    // 查询
    const onQuery = () => {
      userInfo.tableData = [];
      http
        .get("/v1/user/getRecord?auth=" + authParam, {})
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          userInfo.tableData = res.data.data.cdkData;
          return;
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    };

    // 页面加载
    onMounted(() => {
      onQuery();
    });

    const changeTime = (time,format = "YYYY-MM-DD hh:mm:ss") => {
      if (!time) return "";
      var datetime = new Date(time);
      var o = {
        "Y+": datetime.getFullYear(),
        "M+": datetime.getMonth() + 1,
        "D+": datetime.getDate(),
        "h+": datetime.getHours(),
        "m+": datetime.getMinutes(),
        "s+": datetime.getSeconds(),
        "q+": Math.floor((datetime.getMonth() + 3) / 3),
        S: datetime.getMilliseconds(),
      };
      if (/(Y+)/.test(format))
        format = format.replace(
          RegExp.$1,
          (datetime.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      return format;
    };

    return {
      toLogin,
      changeTime,
      handleEdit,
      userInfo,
      onQuery,
      textCopy,
    };
  },
};
</script>


<template>
  <div class="h-screen font-sans login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="relative mx-10 sm:max-w-lg w-full">
        <div
          class="card bg-blue-400 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"
        ></div>
        <div
          class="card bg-red-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"
        ></div>
        <div
          class="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md"
        >
          <label
            for=""
            class="block mt-10 text-2xl text-gray-700 text-center font-semibold"
          >
            提取记录
          </label>
          <div class="flex mt-5 pl-8 pr-8 items-center text-center">
            <hr class="border-gray-300 border-1 w-full rounded-md" />
            <label
              class="block font-medium mr-2 ml-2 text-sm text-gray-400 w-full whitespace-nowrap"
            >
              获取到所有的提取记录查询
            </label>
            <hr class="border-gray-300 border-1 w-full rounded-md" />
          </div>
          <div>
            <div class="mt-12">
              <el-table :data="userInfo.tableData" style="width: 100%">
                <el-table-column label="提取时间" width="210">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <span style="margin-left: 10">{{
                        changeTime(scope.row.extract_time)
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="手机号" width="150">
                  <template #default="scope">
                        <el-tag>{{ scope.row.phone }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="验证码">
                  <template #default="scope">
                    <div>{{ scope.row.code }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <input
              ref="textCopy"
              value=""
              style="opacity: 0; position: absolute"
            />
            <div class="flex mt-12 pl-8 pr-8 mb-10">
              <button
                class="flex bg-blue-500 justify-center font-normal w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="toLogin"
              >
                <!-- <el-icon><ArrowLeftBold /></el-icon> -->
                <div>返回主页</div>
              </button>
            </div>
          </div>
          <!-- <form class="mt-10" @click="onSubmit"></form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
}
</style>

// plugins/loading.js
import { showLoading, closeLoading } from '@/plugins/loadingFun';

export default {
  install: (app, options) => {
    app.provide('$loading', {
      show: showLoading,
      close: closeLoading
    });
  }
};
import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RecordView from '../views/RecordView.vue'
import Errors from '../views/404View.vue'

const routes = [{
    path: '/home',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/record',
    name: 'record',
    component: RecordView
  },
  {
    path: "/",
    redirect: {
      name: "Home"
    }, // 路由重定向
  },
  {
    path: "/:any(.*)",
    name:"notFound",
    component:Errors,
  }
]

// path: "/:any(.*)",
// name: "notFound",
// meta: { intercept: true },
// component: () => import("@/views/errors/404.vue")

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
import { ElMessage } from "element-plus";


// "success" | "warning" | "info" | "error"
export function useMessage(type, message) {
  ElMessage({
    message,
    type,
    duration: 2000,
    customClass: "message-box-custom-class"
  });
}


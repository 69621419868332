import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/tailMain.css'
import LoadingPlugin from '@/plugins/loading.js';
import '@/plugins/styles.css';
import '@/plugins/sweetalert2.css'
createApp(App).use(store).use(LoadingPlugin).use(ElementPlus).use(router).mount('#app')
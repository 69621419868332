<template>
  <router-view/>
  <!-- <router-view></router-view> -->
</template>

<style lang="scss">

html,body{
height: 100%;
  background: url("@/assets/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

</style>

import axios from "axios";
import { showLoading, closeLoading } from '../plugins/loadingFun.js';
const locations = "https://bj.xilemj.cn";
// const locations = "http://127.0.0.1:8080";
// 封装axios请求
const http = axios.create({
  baseURL: locations, // 设置每次请求的地址
  timeout: 30000,
  headers: {
    // userToken: localStorage.getItem("userToken"),
    "Content-Type": "application/x-www-form-urlencoded"
  } // 设置每次请求携带的请求头
});

// axios请求拦截 （请求之前）
http.interceptors.request.use(
  function (config) {
    // 设置发送请求时加载页面
    showLoading();

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
    
  }
);

// axios响应拦截 （请求之后）
http.interceptors.response.use(
  function (response) {
    // 清除加载页面
    closeLoading()

    // if (response.data.code == 2001) {
    //   useMessage("error", response.data.message);
    //   window.location = "/login";
    // }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// 封装axios请求
const http2 = axios.create({
  baseURL: locations, // 设置每次请求的地址
  timeout: 30000,
  headers: {
    // userToken: localStorage.getItem("userToken"),
    "Content-Type": "application/x-www-form-urlencoded"
  } // 设置每次请求携带的请求头
});



export { http, locations,http2 };
